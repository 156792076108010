// import '../styles/main.sass';
import 'styles/global.css';
import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';
import Auth from '../components/Auth'

function MyApp({
  Component,
  pageProps: { session, ...pageProps }
}) {
  return (
    <SessionProvider session={session}>
      <Head>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Head>

      {Component.auth ? (
        <Auth>
          <Component {...pageProps} />
        </Auth>
      ) :
        <Component {...pageProps} />
       }
    </SessionProvider>
  );
}

export default MyApp;
