import Router from 'next/router';
import { useSession, signIn } from "next-auth/react"


const Auth = ({children}) => {
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      // Router.push("/signin")
      signIn('google')
    }
  })

  if (session) {
    return children
  }

  return <>Loading...</>
}

export default Auth
